@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap'); */

@font-face {
  font-family: 'Averta';
  src: url('../src/assets/fonts/AvertaDemo-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: "Averta", sans-serif;
}

.toastBody{
  font-family: 'Averta', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff50;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #71CB9060;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #71CB90;
}